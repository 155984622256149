import React from "react";
import { Helmet } from 'react-helmet';
// nodejs library that concatenates classes
import classnames from "classnames";

// reactstrap components
import {
  Badge,
  Button,
  Card,
  CardBody,
  CardImg,
  FormGroup,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col
} from "reactstrap";

// core components
import MilesNavbar from "components/Navbars/MilesNavbar.jsx";
import ConsultancyFooter from "components/Footers/ConsultancyFooter.jsx";
import MEForm from "components/MEForm.js";

import MenuHead from "../IndexSections/MenuHead.jsx";

// import Hero from "../IndexSections/Hero.jsx";


class ExecCoaching extends React.Component {
  state = {};
  componentDidMount() {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.main.scrollTop = 0;
  }
  render() {
    return (
      <>

      <Helmet>
      <title>Executive Coaching - ME Talent Solutions</title>
      <meta name="description" content="ME Executive Coaching" />
      <meta name="keywords" content="CEO, COO, CHRO, CFO, Senior Executive Talent, Board Director & Non-Executive Talent, Creative Talent, Private Equity, CEO, C-Suite, Chief Executive Officer, Board of Director, Non-executive Director, Board Chairperson, Human Resources, HR, People, Marketing & Sales, Strategy & Corporate Planning, Chief Digital Officer, General Counsel, Creative Talent, Creative Directors, Design Director, Chief Digital Officer, Consumer, Technology, Media, Transportation, Healthcare, Industrial" />
      </Helmet>


        <MilesNavbar />

        <MenuHead />



        <main ref="main">

          <section className="section section-lg">
            <Container>
              <Row className="row-grid align-items-center">
                <Col className="order-md-2" md="10">
                  <h3>Executive Coaching</h3>
                  <p className="lead text-black mt-4">
                  We provide executive coaching and mentoring services to executives at all stages of their career journey. The company is highly regarded for its confidential and personalized approach, cultivating high-profile executives and impressive leaders.
                  <p />
                  Working with us has many benefits for you, your business, and your stakeholders. We are known for our highly confidential, efficient, and professional approach, which is based on establishing a unique relationship with each of our clients.
                  <p />
                  Our focus is unlocking the potential of high-impact leaders to drive breakthrough business performance.
                  </p>

                </Col>

              </Row>
            </Container>
          </section>



          <section className="section pb-0 bg-gradient-white">
            {/* SVG separator */}
            <div className="separator separator-bottom separator-skew zindex-100">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                preserveAspectRatio="none"
                version="1.1"
                viewBox="0 0 2560 100"
                x="0"
                y="0"
              >
                <polygon
                  className="fill-creative"
                  points="0 0 2560 100 0 100"
                />
              </svg>
            </div>
          </section>

          <section className="section section-lg bg-gradient-default">
            <Container className="row-grid align-items-center">
              <Row className="row-grid mt-5">
                <Col lg="10">

                  <h5 className="text-white mt-3">Why Executive Coaching?</h5>
                  <p className="text-white mt-3">


                  <h3></h3>
                  <p>

                  Executive Coaching is a powerful service that can lead to a groundbreaking impact on your business. Whether you are an individual seeking coaching or a business that is developing a coaching culture, we can help you by providing an exceptional coaching solution.
                  <p />Our clients often use our Executive Coaching services for the following reasons:
                  <p/>
                  <li>Executive Coaching is a powerful tool for <strong>unlocking your potential</strong> and maximising performance.</li>
                  <li>The use of an executive coach can help <strong>identify your strengths</strong> and areas for development, creating actionable insights for both you and your organization.</li>
                  <li>In a frantic world, an executive coach can help you prioritise your goals, and achieve <strong>clarity of mind</strong>.</li>
                  <li><strong>Developing your leadership skills.</strong> Our coaches can help you become an executive who is trusted and a market leader in your field.</li>
                  <li>An executive coach can be instrumental to the success of a company since they will help align a company's development and improvement efforts.</li>

                  </p>
                  </p>


                </Col>

              </Row>
            </Container>

          </section>










          <section className="section section-lg">
            <Container>
              <Row className="justify-content-center text-center mb-lg">
                <Col lg="8">
                  <h2 className="display-3">Why ME Consultancy?</h2>
                  <p className="lead text-black mt-4">

                  Executive Coaching is a highly personal service, and can only be delivered through a trusted partner. Our consultants have experience as trusted consultants and advisors to some of the most impactful businesses in the world. Their exceptional market knowledge and understanding of the psychology between interpersonal communications, enables them to be able to rapidly adapt to new challenges and environments and communicate effectively with individuals at all levels.
                  <p />
                  We understand the benefits of developing a coaching culture within organizations and the positive impact that coaching can have on cultivating an individual’s career journey. As part of our coaching process, we offer exclusive access to our bespoke TalentTide® Executive and Business Assessment Tools to support you every step along the way.
                  <p />
                  Our ethical coaching methodology and values have been developed in line with The Global Code of Ethics by The Association for Coaching, to create a positive learning environment for all individual’s engaged with the highest level of professional competence.


                  </p>
                </Col>

              </Row>
                              <MEForm />
            </Container>
          </section>



        </main>
        <ConsultancyFooter />
      </>
    );
  }
}

export default ExecCoaching;
