import React from "react";
import { Helmet } from 'react-helmet';
// nodejs library that concatenates classes
import classnames from "classnames";

// reactstrap components
import {
  Badge,
  Button,
  Card,
  CardBody,
  CardImg,
  FormGroup,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col
} from "reactstrap";

// core components
import MilesNavbar from "components/Navbars/MilesNavbar.jsx";
import ConsultancyFooter from "components/Footers/ConsultancyFooter.jsx";
import MEForm from "components/MEForm.js";
import ServiceTabs from  "../IndexSections/ServiceTabs.jsx";
import MenuHead from "../IndexSections/MenuHead.jsx";

// import Hero from "../IndexSections/Hero.jsx";


class MEAssess extends React.Component {
  state = {};
  componentDidMount() {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.main.scrollTop = 0;
  }
  render() {
    return (
      <>

      <Helmet>
      <title>Executive Assessment - ME Talent Solutions</title>
      <meta name="description" content="Our proprietary Executive Assessment that continuously adapts to market trends and utilizes predictive data analytics. We apply a bespoke hiring methodology that assesses candidate soft skills and their specific behavioral characteristics that can help determine correct alignment with role requirements, team dynamics, and the organizational culture. Our internally-developed executive analysis profiling process assesses seven primary competency pillars:" />
      <meta name="keywords" content="Executive Assessment, Executive Search, Comprehensive Assessment, Talent Assessment, executive strengths, potential, big data, predictive data analytics, CEO, COO, CHRO, CxO, team effectiveness, Intelligence, Succession Planning, Cultural Alignment, Leadership Framework, science of success, salary benchmarking, organizational culture, leadership selection" />
      </Helmet>

        <MilesNavbar />

        <MenuHead />



        <main ref="main">

          <section className="section section-lg">
            <Container>

            <Row className="row-grid align-items-center">
              <Col className="order-md-2" md="6">
                <img
                  alt="..."
                  className="img-fluid floating"
                  src={require("assets/img/theme/ac-phone.png")}
                />
              </Col>
              <Col className="order-md-1" md="6">
                <div className="pr-md-5">
                  <div className="icon icon-lg icon-shape icon-shape-success shadow rounded-circle mb-5">
                    <i className="ni ni-settings-gear-65" />
                  </div>
                  <h3>TalentTide® Executive Assessment</h3>
                  <p>
                  Our proprietary Executive Assessment continuously adapts to market trends, utilizes predictive data analytics, and has been developed in line with expected behaviors and soft-skills, required both on a role and company-wide level.
                  <p />
                  We apply a bespoke hiring methodology that assesses candidate soft skills and their specific behavioral characteristics that can help determine correct alignment with role requirements, team dynamics, and the organizational culture.
                  <p />
                  Our internally-developed executive analysis profiling process assesses seven primary competency pillars:

                  </p>
                  <ul className="list-unstyled mt-5">
                    <li className="py-2">
                      <div className="d-flex align-items-center">
                        <div>
                          <Badge
                            className="badge-circle mr-3"
                            color="success"
                          >
                            <i className="ni ni-user-run" />
                          </Badge>
                        </div>
                        <div>
                          <h6 className="mb-0">
                            Focus
                          </h6>
                        </div>
                      </div>
                    </li>
                    <li className="py-2">
                      <div className="d-flex align-items-center">
                        <div>
                          <Badge
                            className="badge-circle mr-3"
                            color="success"
                          >
                            <i className="ni ni-notification-70" />
                          </Badge>
                        </div>
                        <div>
                          <h6 className="mb-0">
                            Demonstrating Courage
                          </h6>
                        </div>
                      </div>
                    </li>
                    <li className="py-2">
                      <div className="d-flex align-items-center">
                        <div>
                          <Badge
                            className="badge-circle mr-3"
                            color="success"
                          >
                            <i className="ni ni-paper-diploma" />
                          </Badge>
                        </div>
                        <div>
                          <h6 className="mb-0">
                            Commitment to Excellence
                          </h6>
                        </div>
                      </div>
                    </li>
                    <li className="py-2">
                      <div className="d-flex align-items-center">
                        <div>
                          <Badge
                            className="badge-circle mr-3"
                            color="success"
                          >
                            <i className="ni ni-umbrella-13" />
                          </Badge>
                        </div>
                        <div>
                          <h6 className="mb-0">
                            Humility
                          </h6>
                        </div>
                      </div>
                    </li>
                    <li className="py-2">
                      <div className="d-flex align-items-center">
                        <div>
                          <Badge
                            className="badge-circle mr-3"
                            color="success"
                          >
                            <i className="ni ni-chart-bar-32" />
                          </Badge>
                        </div>
                        <div>
                          <h6 className="mb-0">
                            Strategic Capability/Productivity
                          </h6>
                        </div>
                      </div>
                    </li>
                    <li className="py-2">
                      <div className="d-flex align-items-center">
                        <div>
                          <Badge
                            className="badge-circle mr-3"
                            color="success"
                          >
                            <i className="ni ni-button-power" />
                          </Badge>
                        </div>
                        <div>
                          <h6 className="mb-0">
                            Energy
                          </h6>
                        </div>
                      </div>
                    </li>
                    <li className="py-2">
                      <div className="d-flex align-items-center">
                        <div>
                          <Badge
                            className="badge-circle mr-3"
                            color="success"
                          >
                            <i className="ni ni-world-2" />
                          </Badge>
                        </div>
                        <div>
                          <h6 className="mb-0">
                            Collaborative Nature/Develop Influence
                          </h6>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </Col>
            </Row>

            </Container>
          </section>



          <section className="section pb-0 bg-gradient-white">
            {/* SVG separator */}
            <div className="separator separator-bottom separator-skew zindex-100">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                preserveAspectRatio="none"
                version="1.1"
                viewBox="0 0 2560 100"
                x="0"
                y="0"
              >
                <polygon
                  className="fill-creative"
                  points="0 0 2560 100 0 100"
                />
              </svg>
            </div>
          </section>

          <section className="section section-lg bg-gradient-default">
            <Container className="row-grid align-items-center">
              <Row className="row-grid mt-5">
                <Col lg="10">

                  <h5 className="text-white mt-3">Benefits of Executive Assessment</h5>
                  <p className="text-white mt-3">

                  <p>
                  The executive assessment process is used as an internal process for all of our Executive Search & Succession Planning projects. However, access to our assessment process outside of these services is also available. Our Talent Assessment process is increasingly recognized as a critical component of company performance and success.
                  <p/>
                  Our clients often use our Talent Assessment services for the following reasons:
                  <p/>
                  <li>The process allows you to assess a leader’s potential based around key metrics that help identify the most transformation and effective leaders in the world.</li>
                  <li>It safeguards your hiring decisions to prevent a bad hire for your business.</li>
                  <li>Engages with executives using a highly effective and proven engagement and assessment process, that is designed to provide a positive experience and cultivating candidate journey.</li>


                  </p>
                  </p>



                </Col>

              </Row>
            </Container>

          </section>










          <section className="section section-lg">
            <Container>
              <Row className="justify-content-center text-center mb-lg">
                <Col lg="8">
                  <h2 className="display-3">Why ME Consultancy?</h2>
                  <p className="lead text-black mt-4">

Traditionally companies <strong>hire</strong> based on <strong>skills, experience, and knowledge</strong>. However, they <strong>fire</strong> based on <strong>actions, behaviors, and performance</strong>.
<p />We apply a bespoke hiring methodology that <strong>assesses candidate soft skills</strong> and their <strong>specific behavioral characteristics</strong> that can help determine correct alignment in relation to <strong>role requirements, team dynamics</strong>, and the <strong>organizational culture</strong>.
<p />Our <strong>behavioral interview assessments</strong> are developed in line with expected behaviors and soft-skills, required both on a role and company-wide level.

                  </p>
                </Col>

              </Row>
                              <MEForm />
            </Container>
          </section>



        </main>
        <ConsultancyFooter />
      </>
    );
  }
}

export default MEAssess;
