import React from "react";
import classnames from "classnames";


import {
  Button,
  Row,
  Col
} from "reactstrap";

class MEForm extends React.Component {
  state = {
    iconTabs: 1,
    plainTabs: 1
  };
  toggleNavs = (e, state, index) => {
    e.preventDefault();
    this.setState({
      [state]: index
    });
  };
  render() {
    return (
      <>

      <Row className="justify-content-center text-center mb-lg">
        <Col lg="8">
          <h2 className="display-3">Speak with our team</h2>

          <p className="lead text-black mt-4">

          Seeking great talent or a new opportunity?
          <p />

          <Button
            className="btn-icon btn-2 ml-1"
            outline type="button"
            color="primary"
            type="button"
            href="https://calendly.com/me-talent-solutions/schedule"
            target="_blank"
          >
                <span className="btn-inner--icon mr-1">
                  <i className="ni ni-paper-diploma" />
                </span>
            <span className="btn-inner--text">Schedule a call</span>
          </Button>

          </p>
        </Col>

      </Row>
      </>
    );
  }
}

export default MEForm;
