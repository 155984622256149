import React from "react";
import { Helmet } from 'react-helmet';
// nodejs library that concatenates classes
import classnames from "classnames";

// reactstrap components
import {
  Badge,
  Button,
  Card,
  CardBody,
  CardImg,
  FormGroup,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col
} from "reactstrap";

// core components
import MilesNavbar from "components/Navbars/MilesNavbar.jsx";
import ConsultancyFooter from "components/Footers/ConsultancyFooter.jsx";
import MEForm from "components/MEForm.js";
import MenuHead from "../IndexSections/MenuHead.jsx";

import Mailto from 'react-protected-mailto'

// import Hero from "../IndexSections/Hero.jsx";


class Contact extends React.Component {
  state = {};
  componentDidMount() {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.main.scrollTop = 0;
  }
  render() {
    return (
      <>

      <Helmet>
      <title>Contact Us - ME Talent Solutions</title>
      <meta name="description" content="We combine worldwide digital talent acquisition with a personal touch, to enable both sustainable growth and powerful business partnerships and collaborations. Our consultants have first-hand experience leading Executive Search for leading global corporations. If you believe that the ethics and beliefs of ME Consultancy align with your company, and you have an interest in working alongside us on a people-orientated business project, then we look forward to hearing from you." />
      <meta name="keywords" content="ME Talent Solutions, ME Talent, ME Consultancy, Executive Search, Executive Assessment, Talent Acquisition, CEO Search, Creative Search, Headhunters, Global Search, Succession Planning, Digital HR Tools, Bespoke HR Software, Proprietary Assessment" />
      </Helmet>

        <MilesNavbar />

        <MenuHead />



        <main ref="main">

          <section className="section section-lg">
            <Container>
              <Row className="justify-content-center text-center mb-lg">
                <Col lg="8">
                  <h2 className="display-3">Contact Us</h2>
                  <p className="lead text-black mt-4">

                  We combine worldwide digital talent acquisition with a personal touch, to enable both sustainable growth and powerful business partnerships and collaborations. Our consultants have first-hand experience leading Executive Search for leading global corporations.
                  <p />
                  If you believe that the ethics and beliefs of ME Consultancy align with your company, and you have an interest in working alongside us on a people-orientated business project, then we look forward to hearing from you.
                  <p />
                  <img
                    alt="..."
                    width="20"
                    className="img-fluid"
                    src={require("assets/img/theme/phone.png")}
                  /> +44 870 321 4512 / +1 512 524 6035
                  <p />
                  <img
                    alt="..."
                    width="20"
                    className="img-fluid"
                    src={require("assets/img/theme/email.png")}
                  /> info@me-talent.com
                  </p>
                </Col>

              </Row>
                              <MEForm />
                              <Row className="justify-content-center text-center mb-lg">

                              </Row>



            </Container>
          </section>



        </main>
        <ConsultancyFooter />
      </>
    );
  }
}

export default Contact;
