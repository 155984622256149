/*!

=========================================================
* Argon Design System React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-design-system-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-design-system-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// nodejs library that concatenates classes
import classnames from "classnames";

// reactstrap components
import {
  Card,
  CardBody,
  NavItem,
  NavLink,
  Nav,
  TabContent,
  TabPane,
  Row,
  Col
} from "reactstrap";

class HRServiceTabs extends React.Component {
  state = {
    iconTabs: 1,
    plainTabs: 1
  };
  toggleNavs = (e, state, index) => {
    e.preventDefault();
    this.setState({
      [state]: index
    });
  };
  render() {
    return (
      <>

        <Row className="justify-content-center">
          <Col lg="10">
            {/* Tabs with icons */}

            <div className="nav-wrapper">
              <Nav
                className="nav-fill flex-column flex-md-row"
                id="tabs-icons-text"
                pills
                role="tablist"
              >
                <NavItem>
                  <NavLink
                    aria-selected={this.state.iconTabs === 1}
                    className={classnames("mb-sm-3 mb-md-0", {
                      active: this.state.iconTabs === 1
                    })}
                    onClick={e => this.toggleNavs(e, "iconTabs", 1)}
                    href="#"
                    role="tab"
                  >
                    <i className="ni ni-bullet-list-67 mr-2" />
                    Scope
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    aria-selected={this.state.iconTabs === 2}
                    className={classnames("mb-sm-3 mb-md-0", {
                      active: this.state.iconTabs === 2
                    })}
                    onClick={e => this.toggleNavs(e, "iconTabs", 2)}
                    href="#"
                    role="tab"
                  >
                    <i className="ni ni-circle-08 mr-2" />
                    Specialized Market Knowledge
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    aria-selected={this.state.iconTabs === 3}
                    className={classnames("mb-sm-3 mb-md-0", {
                      active: this.state.iconTabs === 3
                    })}
                    onClick={e => this.toggleNavs(e, "iconTabs", 3)}
                    href="#"
                    role="tab"
                  >
                    <i className="ni ni-calendar-grid-58 mr-2" />
                      HR Role Expertise
                  </NavLink>
                </NavItem>
              </Nav>
            </div>


            <Card className="shadow">
              <CardBody>
                <TabContent activeTab={"iconTabs" + this.state.iconTabs}>
                  <TabPane tabId="iconTabs1">
                    <p className="description">
                      <li>Global Chief Human Resources Officers Practice</li>
                      <li>HR Board Directors & Non-Executive Talent for HR Function</li>
                      <li>Culture Leaders</li>
                      <li>Diversity and Inclusion Professionals (Chief Diversity Officers)</li>
                    </p>

                  </TabPane>
                  <TabPane tabId="iconTabs2">
                    <p className="description">
                        <li>Private Equity</li>
                        <li>Consumer Retail</li>
                        <li>Food and Beverage</li>
                        <li>Luxury Markets</li>
                        <li>Hospitality</li>
                        <li>Production and Packaging</li>
                        <li>Technology & Communications</li>
                        <li>Media</li>
                        <li>Transportation</li>
                        <li>Financial Services</li>
                        <li>Healthcare</li>
                        <li>Industrial</li>
                    </p>
                  </TabPane>
                  <TabPane tabId="iconTabs3">
                    <p className="description">
                      <li>CHRO (Chief Human Resources Officers)</li>
                      <li>Group HRD</li>
                      <li>Chief People Officer</li>
                      <li>Chief Talent Officer</li>
                      <li>VP Compensation and Benefits</li>
                      <li>VP Global Recruitment</li>
                      <li>Divisional HR Director</li>
                    </p>
                  </TabPane>
                </TabContent>
              </CardBody>
            </Card>
          </Col>

        </Row>
      </>
    );
  }
}

export default HRServiceTabs;
