/*!

=========================================================
* Argon Design System React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-design-system-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-design-system-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

import "assets/vendor/nucleo/css/nucleo.css";
import "assets/vendor/font-awesome/css/font-awesome.min.css";
import "assets/scss/argon-design-system-react.scss";
import "assets/css/miles.css"

import MConsultancy from "views/examples/MConsultancy.jsx";
import ExecSearch from "views/examples/ExecSearch.jsx";
import ExecCoaching from "views/examples/ExecCoaching.jsx";
import HRExecSearch from "views/examples/HRExecSearch.jsx";
import BoardSearch from "views/examples/BoardSearch.jsx";
import SPlanning from "views/examples/SPlanning.jsx";
import MEAssess from "views/examples/MEAssess.jsx";
import About from "views/examples/About.jsx";
import Contact from "views/examples/Contact.jsx";
import Careers from "views/examples/Careers.jsx";
import Schedule from "views/examples/Schedule.jsx";
import Confirm from "views/examples/Confirm.jsx";





ReactDOM.render(
  <BrowserRouter>
    <Switch>
      <Route path="/" exact render={props => <MConsultancy {...props} />} />
      <Route path="/executive-search" exact render={props => <ExecSearch {...props} />} />
      <Route path="/executive-coaching" exact render={props => <ExecCoaching {...props} />} />
      <Route path="/chro-search" exact render={props => <HRExecSearch {...props} />} />
      <Route path="/board-search" exact render={props => <BoardSearch {...props} />} />
      <Route path="/succession-planning" exact render={props => <SPlanning {...props} />} />
      <Route path="/executive-assessment" exact render={props => <MEAssess {...props} />} />
      <Route path="/about" exact render={props => <About {...props} />} />
      <Route path="/contact" exact render={props => <Contact {...props} />} />
      <Route path="/careers" exact render={props => <Careers {...props} />} />
      <Route path="/schedule" exact render={props => <Schedule {...props} />} />
      <Route path="/confirmation" exact render={props => <Confirm {...props} />} />

      <Redirect to="/" />
    </Switch>
  </BrowserRouter>,
  document.getElementById("root")
);
