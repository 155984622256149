/*!

=========================================================
* Argon Design System React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-design-system-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-design-system-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import { Button, Container, Row, Col, UncontrolledCarousel } from "reactstrap";
import TextLoop from "react-text-loop";

const items = [
  {
    src: require("assets/img/theme/preston_office.png"),
    altText: "",
    caption: "",
    header: ""
  },
  {
    src: require("assets/img/theme/cvs.png"),
    altText: "",
    caption: "",
    header: ""
  },
  {
    src: require("assets/img/theme/hub.png"),
    altText: "",
    caption: "",
    header: ""
  }

];

class ConsultancyCarousel extends React.Component {
  render() {
    return (
      <>
        <a id="MilesElectricMedia">
        <section className="section section-shaped">
          <div className="shape shape-style-1 shape-default">
            <span />
            <span />
            <span />
            <span />
            <span />
            <span />
          </div>
          <Container className="py-md">
            <Row className="justify-content-between align-items-center">
              <Col className="mb-5 mb-lg-0" lg="5">
                <h4 className="text-white font-weight-light">

              <TextLoop  interval={1500} children={["ME ", "Market Excellence ", "Motivational Enhancement", "Measuring Effectiveness ", "Mentoring Employees ", "Millennial Entrepreneurs ", "Media Executive ", "Mainstream  Energy", "Mechanical Engineering ", "Management Evaluation ", "Marketing Executive ", "Mental Efficiency", "Management Engineering", "Maximum Effort" ]} />
              </h4><h1 className="text-white font-weight-light">Talent Solutions
                </h1>
                <p className="lead text-white mt-4">

                ME Consultancy attracts, cultivates and represents world-leading executive talent, predominantly in Europe, North America, and Asia.
<p />
                Utilizing extensive personal networks, we proactively engage with key business leaders supporting succession planning, talent attraction, and provide confidential talent market insights.
<p />
                We help our clients retain great talent, by supporting them beyond our engagement and interview process.


                  </p>
                <Button
                  className="btn-white mt-4"
                  color="default"
                  href="mailto:info@me-talent.com"
                >
                  Contact Us
                </Button>
              </Col>
              <Col className="mb-lg-auto" lg="6">
                <div className="rounded shadow-lg overflow-hidden transform-perspective-right">
                  <UncontrolledCarousel items={items} />
                </div>
              </Col>
            </Row>
          </Container>
          {/* SVG separator */}
          <div className="separator separator-bottom separator-skew">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="none"
              version="1.1"
              viewBox="0 0 2560 100"
              x="0"
              y="0"
            >
              <polygon className="fill-white" points="2560 0 2560 100 0 100" />
            </svg>
          </div>
        </section>
        </a>
      </>
    );
  }
}

export default ConsultancyCarousel;
