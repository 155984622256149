import React from "react";
import { Helmet } from 'react-helmet';
// nodejs library that concatenates classes
import classnames from "classnames";

// reactstrap components
import {
  Badge,
  Button,
  Card,
  CardBody,
  CardImg,
  FormGroup,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col
} from "reactstrap";

// core components
import MilesNavbar from "components/Navbars/MilesNavbar.jsx";
import ConsultancyFooter from "components/Footers/ConsultancyFooter.jsx";
import ServiceTabs from  "../IndexSections/ServiceTabs.jsx";
import MenuHead from "../IndexSections/MenuHead.jsx";

// import Hero from "../IndexSections/Hero.jsx";


class About extends React.Component {
  state = {};
  componentDidMount() {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.main.scrollTop = 0;
  }
  render() {
    return (
      <>

      <Helmet>
      <title>About - ME Talent Solutions</title>
      <meta name="description" content="Over the last 20 years we have seen dramatic changes in the recruitment market, and the strategic leadership requirements of businesses will continue to adapt to a rapidly changing market. Our understanding of our client’s strategic mission, goals and culture help us find leaders who are a best fit for an organization’s specific requirements. Our mission is to provide a world-class recruitment service where candidates are not just seen as a number in a shortlist, but can be personally identified by their personal journey, successes and key motivators. We work with people to build the world of tomorrow. Providing innovative solutions that adapt to the ever-changing needs of our customers and the world economy. We innovate to build legacies and do business like there is a tomorrow." />
      <meta name="keywords" content="Executive Search, Executive Assessment, Talent Assessment, CEO, COO, CHRO, VP, Diretor, Private Equity, Consumer, Technology, Media, Global, Executive Recruitment, Global Talent" />
      </Helmet>
        <MilesNavbar />

        <MenuHead />



        <main ref="main">

          <section className="section section-lg">
            <Container>
              <Row className="row-grid align-items-center">
                <Col className="order-md-2" md="10">
                  <h3>ME Talent Solutions</h3>
                  <p className="lead text-black mt-4">
                  Over the last 20 years we have seen dramatic changes in the recruitment market, and the strategic leadership requirements of businesses will continue to adapt to a rapidly changing market. Our understanding of our client’s strategic mission, goals and culture help us find leaders who are a best fit for an organization’s specific requirements.
                  <p />
                  Our mission is to provide a world-class recruitment service where candidates are not just seen as a number in a shortlist, but can be personally identified by their personal journey, successes and key motivators. We work with people to build the world of tomorrow.
                  <p />
                  Providing innovative solutions that adapt to the ever-changing needs of our customers and the world economy.
                  <p />
                  We innovate to build legacies and do business like there is a tomorrow.
                  </p>



                  <ul className="list-unstyled mt-5">
                    <h4>Beliefs and Core Values</h4>
                    <li className="py-2">
                      <div className="d-flex align-items-center">
                        <div>
                          <Badge
                            className="badge-circle mr-3"
                            color="info"
                          >
                            <i className="ni ni-world" />
                          </Badge>
                        </div>
                        <div>
                          <h6 className="mb-0">
                            Diversity & Equality
                          </h6>
                        </div>
                      </div>
                    </li>
                    <li className="py-2">
                      <div className="d-flex align-items-center">
                        <div>
                          <Badge
                            className="badge-circle mr-3"
                            color="info"
                          >
                            <i className="ni ni-spaceship" />
                          </Badge>
                        </div>
                        <div>
                          <h6 className="mb-0">
                            Sustainability
                          </h6>
                        </div>
                      </div>
                    </li>
                    <li className="py-2">
                      <div className="d-flex align-items-center">
                        <div>
                          <Badge
                            className="badge-circle mr-3"
                            color="info"
                          >
                            <i className="ni ni-ruler-pencil" />
                          </Badge>
                        </div>
                        <div>
                          <h6 className="mb-0">
                            Nurturing Creativity and Innovation
                          </h6>
                        </div>
                      </div>
                    </li>
                    <li className="py-2">
                      <div className="d-flex align-items-center">
                        <div>
                          <Badge
                            className="badge-circle mr-3"
                            color="info"
                          >
                            <i className="ni ni-air-baloon" />
                          </Badge>
                        </div>
                        <div>
                          <h6 className="mb-0">
                            Investing in The Future
                          </h6>
                        </div>
                      </div>
                    </li>
                    <li className="py-2">
                      <div className="d-flex align-items-center">
                        <div>
                          <Badge
                            className="badge-circle mr-3"
                            color="info"
                          >
                            <i className="ni ni-key-25" />
                          </Badge>
                        </div>
                        <div>
                          <h6 className="mb-0">
                            Trust
                          </h6>
                        </div>
                      </div>
                    </li>
                    <li className="py-2">
                      <div className="d-flex align-items-center">
                        <div>
                          <Badge
                            className="badge-circle mr-3"
                            color="info"
                          >
                            <i className="ni ni-trophy" />
                          </Badge>
                        </div>
                        <div>
                          <h6 className="mb-0">
                            Integrity
                          </h6>
                        </div>
                      </div>
                    </li>
                    <li className="py-2">
                      <div className="d-flex align-items-center">
                        <div>
                          <Badge
                            className="badge-circle mr-3"
                            color="info"
                          >
                            <i className="ni ni-lock-circle-open" />
                          </Badge>
                        </div>
                        <div>
                          <h6 className="mb-0">
                            Transparency
                          </h6>
                        </div>
                      </div>
                    </li>
                  </ul>


                </Col>

              </Row>
            </Container>
          </section>



          <section className="section pb-0 bg-gradient-white">
            {/* SVG separator */}
            <div className="separator separator-bottom separator-skew zindex-100">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                preserveAspectRatio="none"
                version="1.1"
                viewBox="0 0 2560 100"
                x="0"
                y="0"
              >
                <polygon
                  className="fill-creative"
                  points="0 0 2560 100 0 100"
                />
              </svg>
            </div>
          </section>

          <section className="section section-lg bg-gradient-default">
            <Container className="pt-lg pb-300">
              <Row className="row-grid mt-5">
                <Col lg="4">
                  <div className="icon icon-lg icon-shape bg-gradient-white shadow rounded-circle text-primary">
                    <i className="ni ni-world text-primary" />
                  </div>
                  <h5 className="text-white mt-3">Global Executive Search</h5>
                  <p className="text-white mt-3">
                    <li>Our success is driven by your success. We have a vested interest in helping your business, and our team of expert professionals know the challenges that our clients face.</li>
                    <li>Nobody wants to be a “file” – we work with people and not “databases” or “files”. </li>
                    <li>We help you retain talent by supporting you beyond the search process.</li>
                    <li>Our consultants have experience delivering projects in most global geographies and markets, and conduct every search with a global reach vision.</li>

                  </p>
                </Col>
                <Col lg="4">
                  <div className="icon icon-lg icon-shape bg-gradient-white shadow rounded-circle text-primary">
                    <i className="ni ni-bullet-list-67 text-primary" />
                  </div>
                  <h5 className="text-white mt-3">Talent Assessment</h5>
                  <p className="text-white mt-3">
                    <li>We help match an individual's purpose with company mission.</li>
                    <li>We cultivate executive talent through our bespoke competency development platform.</li>
                    <li>Through our bespoke talent platform, talent analytics can be shared real-time with your hiring team.</li>
                    <li>Our Culture & Competency Based Assessment assesses 15 core leadership traits.</li>
                  </p>
                </Col>
                <Col lg="4">
                  <div className="icon icon-lg icon-shape bg-gradient-white shadow rounded-circle text-primary">
                    <i className="ni ni-zoom-split-in text-primary" />
                  </div>
                  <h5 className="text-white mt-3">Succession Planning</h5>
                  <p className="text-white mt-3">
                    <li>Our bespoke succession project portal provides a fully customizable solution based on the needs of your business or hiring project.</li>
                    <li>Compare your existing talent to external benchmarks and a live candidate talent pool.</li>
                    <li>Talent pool the most business critical functions within your company to future-proof your success.</li>
                    <li>Continuously update your executive success profiles with a diverse talent pipeline.</li>
                  </p>
                </Col>
              </Row>
            </Container>

          </section>









          <section className="section section-lg">
            <Container>
              <Row className="justify-content-center text-center mb-lg">
                <Col lg="8">
                  <h2 className="display-3">Developing Our People</h2>
                  <p className="lead text-black mt-4">
                    Our clients, candidates and colleagues are extremely important to us, which is why we dedicate significant investment and time into making the following training and support accessible:
                    <p />
                    <li>Resilience Training</li>
                    <li>Mindfulness & Mental Wellbeing</li>
                    <li>Nutrition, Health & Fitness</li>
                    <li>Reflective Journaling Guidance</li>
                    <li>Financial Education</li>
                    <li>Leadership Coaching</li>


                  </p>
                </Col>

              </Row>
            </Container>
          </section>



        </main>
        <ConsultancyFooter />
      </>
    );
  }
}

export default About;
