import React from "react";
import { Helmet } from 'react-helmet';
// nodejs library that concatenates classes
import classnames from "classnames";

// reactstrap components
import {
  Badge,
  Button,
  Card,
  CardBody,
  CardImg,
  FormGroup,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col
} from "reactstrap";

// core components
import MilesNavbar from "components/Navbars/MilesNavbar.jsx";
import ConsultancyFooter from "components/Footers/ConsultancyFooter.jsx";
import MenuHead from "../IndexSections/MenuHead.jsx";

// import Hero from "../IndexSections/Hero.jsx";


class Careers extends React.Component {
  state = {};
  componentDidMount() {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.main.scrollTop = 0;
  }
  render() {
    return (
      <>

      <Helmet>
      <title>Careers - ME Talent Solutions</title>
      <meta name="description" content="Great businesses are built by strong teams with resilience to change. Our people are our biggest asset. Together we are on a journey to make a difference and are passionate about the amazing things that we can achieve. We champion our people and our customers. We innovate to build legacies and do business like there is a tomorrow." />
      <meta name="keywords" content="Project Delivery, Business Development, Delivery Support, Careers, Recruitment, Key Account Management, Account Executive, Sales, Project Management, Leadership, Management, Jobs" />
      </Helmet>

        <MilesNavbar />
        <MenuHead />
        <main ref="main">
          <section className="section section-lg">
            <Container>
              <Row className="row-grid align-items-center">
                <Col className="order-md-2" md="10">

                  <h3>Careers</h3>
                  <p className="lead text-black mt-4">
                  Great businesses are built by strong teams with resilience to change. Our people are our biggest asset. Together we are on a journey to make a difference and are passionate about the amazing things that we can achieve. We champion our people and our customers. We innovate to build legacies and do business like there is a tomorrow.
                  </p>
                </Col>
              </Row>
            </Container>

            <Container className="pt-lg pb-300">
              <Row className="row-grid mt-5">
                <Col lg="4">
                  <div className="icon icon-lg icon-shape bg-gradient-white shadow rounded-circle text-primary">
                    <i className="ni ni-time-alarm text-warning" />
                  </div>
                  <h5 className="text-black mt-3">Project Delivery</h5>
                  <p className="text-black mt-3">
                    <li>This is a fast-paced role, where you will be managing various projects and clients. This can often be a highly rewarding role where you can directly help people secure their next dream job.</li>
                    <li>To be successful in this role, you need a keen eye for detail, great people skills, and unafraid to have conversations directly with senior executives from some of the largest and most influential companies in the world.</li>
                    <li>Our projects include Executive Search, Succession Planning, Business Intelligence, Talent Assessment and much more. During your training, you may be allocated a core service speciality, but of course, you will have the opportunity to work within all our delivery business units.</li>

                  </p>
                </Col>
                <Col lg="4">
                  <div className="icon icon-lg icon-shape bg-gradient-white shadow rounded-circle text-primary">
                    <i className="ni ni-briefcase-24 text-warning" />
                  </div>
                  <h5 className="text-black mt-3">Business Development</h5>
                  <p className="text-black mt-3">
                    <li>Within our Business Development team, you will be responsible for representing the business to new and existing clients. This will involve key account management, and ensuring that our clients always receive the best possible experience.</li>
                    <li>We hope you have your passport at the ready, as this role may require global travel across Europe, the USA, Asia and even as far as Australia.</li>
                    <li>Providing solutions to our clients is what we do. You will be responsible for showcasing our services, and be confident in sharing all the reasons why we are the trusted talent partner to some of the most influential and powerful companies in the world.</li>
                    <li>NOTE: Due to COVID-19 safety restrictions, we have temporarily suspended all international travel for the safety of our team.  </li>
                  </p>
                </Col>
                <Col lg="4">
                  <div className="icon icon-lg icon-shape bg-gradient-white shadow rounded-circle text-primary">
                    <i className="ni ni-delivery-fast text-warning" />
                  </div>
                  <h5 className="text-black mt-3">Delivery Support</h5>
                  <p className="text-black mt-3">
                    <li>As a member of our Delivery Support team, you will be responsible for providing administrative and logistics support.</li>
                    <li>Our Delivery Support team get hands-on in scheduling appointments, booking meeting rooms, preparing executive profiles and assisting our delivery team in candidate research and identification.</li>
                    <li>You will also be involved in our Quality Assurance processes. These are strict Service Delivery Standards that ensure we always provide the best delivery performance as expected by our clients.</li>
                    <li>An important part of our Delivery Support role you will be issuing client contracts, invoicing and ensuring our legal compliance policies are followed at all times.</li>
              </p>
                </Col>
              </Row>
              <p />
              <h1>There are currently no vacancies available.</h1>
              However, we are always keen to meet energetic and talented professionals who would like to join our team.
              <p />
              If you wish to be considered for any future positions, please send your CV and covering letter to: recruitment(at)me-talent.com
            </Container>
          </section>
        </main>
        <ConsultancyFooter />
      </>
    );
  }
}

export default Careers;
