import React from "react";
import { Helmet } from 'react-helmet';
// nodejs library that concatenates classes
import classnames from "classnames";

// reactstrap components
import {
  Badge,
  Button,
  Card,
  CardBody,
  CardImg,
  FormGroup,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col
} from "reactstrap";

// core components
import MilesNavbar from "components/Navbars/MilesNavbar.jsx";
import ConsultancyFooter from "components/Footers/ConsultancyFooter.jsx";
import MEForm from "components/MEForm.js";
import ServiceTabs from  "../IndexSections/ServiceTabs.jsx";
import MenuHead from "../IndexSections/MenuHead.jsx";

// import Hero from "../IndexSections/Hero.jsx";


class SPlanning extends React.Component {
  state = {};
  componentDidMount() {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.main.scrollTop = 0;
  }
  render() {
    return (
      <>

      <Helmet>
      <title>Succession Planning - ME Talent Solutions</title>
      <meta name="description" content="Succession Planning does not just uncover talent that is available now, but opens the search radius to all talent who may not be immediately available. Succession Planning supports your business in both active/successive role searches. You may need to proactively prepare talent between 3-18 months in advance, whilst comparing internal talent to external talent. Succession Planning does not just uncover talent that is available now, but opens the search radius to all talent who may not be immediately available." />
      <meta name="keywords" content="Succession Planning, Talent Pool, CEO Succession, CHRO Succession, CEO, COO, CHRO, CFO, Senior Executive Talent, Board Director & Non-Executive Talent, Creative Talent, Private Equity, CEO, C-Suite, Chief Executive Officer, Board of Director, Non-executive Director, Board Chairperson, Human Resources, HR, People, Marketing & Sales, Strategy & Corporate Planning, Chief Digital Officer, General Counsel, Creative Talent, Creative Directors, Design Director, Chief Digital Officer, Consumer, Technology, Media, Transportation, Healthcare, Industrial" />
      </Helmet>

        <MilesNavbar />

        <MenuHead />



        <main ref="main">

          <section className="section section-lg">
            <Container>
              <Row className="row-grid align-items-center">
                <Col className="order-md-2" md="10">
                  <h3>Succession Planning</h3>
                  <p className="lead text-black mt-4">

                  ME Consultancy provides global Succession Planning & Talent Pooling for Senior Level / C-Suite talent. (Director Level to C-Suite), to both multi-billion organizations, and also private equity clients. The company is highly regarded for its confidential and personalized approach, representing high profile executives and impressive leaders.
                  <p />
                  Working with us has many benefits for you, your business, and your stakeholders. We are known for our highly confidential, efficient, and professional approach, which is based on establishing a unique relationship with each of our clients.
                  <p />
                  Utilizing extensive personal networks, and digital tools we identify, engage, and attract top executive talent and we help our clients retain talent by supporting them beyond the search process.
                  <p />
                  Our focus is high-impact leaders, predominantly VP/Director + above, who can drive breakthrough business performance.

                  </p>

                </Col>

              </Row>
            </Container>
          </section>



          <section className="section pb-0 bg-gradient-white">
            {/* SVG separator */}
            <div className="separator separator-bottom separator-skew zindex-100">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                preserveAspectRatio="none"
                version="1.1"
                viewBox="0 0 2560 100"
                x="0"
                y="0"
              >
                <polygon
                  className="fill-creative"
                  points="0 0 2560 100 0 100"
                />
              </svg>
            </div>
          </section>

          <section className="section section-lg bg-gradient-default">
            <Container className="row-grid align-items-center">
              <Row className="row-grid mt-5">
                <Col lg="10">

                  <h5 className="text-white mt-3">Why Executive Succession Planning?</h5>
                  <p className="text-white mt-3">


                  <h3></h3>
                  <p>

                  Succession Planning supports your business in both active/successive role searches. You may need to proactively prepare talent between 3-18 months in advance, whilst comparing internal talent to external talent. Succession Planning does not just uncover talent that is available now, but opens the search radius to all talent who may not be immediately available.

                  Our clients often use our Succession Planning services for the following reasons:

                  <p/>
                  <li>Your internal bench lacks internal succession talent.</li>
                  <li>You do not have the time or resources to conduct a full talent mapping/succession talent pooling project – this process is resource-intensive and requires specialized and dedicated consultants.</li>
                  <li>Personal and company networks do not provide the right talent that you need, and you now need to extend your network. Our consultants are among the most well-networked people in the business.</li>


                  </p>
                  </p>

                                      <ServiceTabs />

                </Col>

              </Row>
            </Container>

          </section>










          <section className="section section-lg">
            <Container>
              <Row className="justify-content-center text-center mb-lg">
                <Col lg="8">
                  <h2 className="display-3">Why ME Consultancy?</h2>
                  <p className="lead text-black mt-4">

Traditionally companies <strong>hire</strong> based on <strong>skills, experience, and knowledge</strong>. However, they <strong>fire</strong> based on <strong>actions, behaviors, and performance</strong>.
<p />We apply a bespoke hiring methodology that <strong>assesses candidate soft skills</strong> and their <strong>specific behavioral characteristics</strong> that can help determine correct alignment in relation to <strong>role requirements, team dynamics</strong>, and the <strong>organizational culture</strong>.
<p />Our <strong>behavioral interview assessments</strong> are developed in line with expected behaviors and soft-skills, required both on a role and company-wide level.

                  </p>
                </Col>

              </Row>
                              <MEForm />
            </Container>
          </section>



        </main>
        <ConsultancyFooter />
      </>
    );
  }
}

export default SPlanning;
