import React from "react";
import { Helmet } from 'react-helmet';
// nodejs library that concatenates classes
import classnames from "classnames";

// reactstrap components
import {
  Badge,
  Button,
  Card,
  CardBody,
  CardImg,
  FormGroup,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col
} from "reactstrap";

// core components
import MilesNavbar from "components/Navbars/MilesNavbar.jsx";
import ConsultancyFooter from "components/Footers/ConsultancyFooter.jsx";
import MenuHead from "../IndexSections/MenuHead.jsx";


// import Hero from "../IndexSections/Hero.jsx";


class Schedule extends React.Component {
  state = {};
  componentDidMount() {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.main.scrollTop = 0;
  }
  render() {
    return (
      <>

      <Helmet>
      <title>Schedule an introductory call with ME Talent Solutions</title>
      <meta name="description" content="We combine worldwide digital talent acquisition with a personal touch, to enable both sustainable growth and powerful business partnerships and collaborations. Our consultants have first-hand experience leading Executive Search for leading global corporations. If you believe that the ethics and beliefs of ME Consultancy align with your company, and you have an interest in working alongside us on a people-orientated business project, then we look forward to hearing from you." />
      <meta name="keywords" content="ME Talent Solutions, ME Talent, ME Consultancy, Executive Search, Executive Assessment, Talent Acquisition, CEO Search, Creative Search, Headhunters, Global Search, Succession Planning, Digital HR Tools, Bespoke HR Software, Proprietary Assessment" />
      </Helmet>

        <MilesNavbar />

        <MenuHead />



        <main ref="main">

          <section className="section section-lg">
            <Container>
              <Row className="justify-content-center text-center mb-lg">
                <Col lg="8">
                  <h2 className="display-3">Get Started</h2>

                  <p className="lead text-black mt-4">

                  Seeking great talent or a new opportunity?
                  <p />

                  <Button
                    className="btn-icon btn-2 ml-1"
                    outline type="button"
                    color="primary"
                    type="button"
                    href="https://calendly.com/me-talent-solutions/schedule"
                    target="_blank"
                  >
                        <span className="btn-inner--icon mr-1">
                          <i className="ni ni-paper-diploma" />
                        </span>
                    <span className="btn-inner--text">Schedule a call</span>
                  </Button>

                  </p>
                </Col>

              </Row>

            </Container>
          </section>



        </main>
        <ConsultancyFooter />
      </>
    );
  }
}

export default Schedule;
