import React from "react";
import { Helmet } from 'react-helmet';
// nodejs library that concatenates classes
import classnames from "classnames";

// reactstrap components
import {
  Badge,
  Button,
  Card,
  CardBody,
  CardImg,
  FormGroup,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col
} from "reactstrap";

// core components
import MilesNavbar from "components/Navbars/MilesNavbar.jsx";
import ConsultancyFooter from "components/Footers/ConsultancyFooter.jsx";
import MEForm from "components/MEForm.js";
import HRServiceTabs from  "../IndexSections/HRServiceTabs.jsx";
import MenuHead from "../IndexSections/MenuHead.jsx";

// import Hero from "../IndexSections/Hero.jsx";


class HRExecSearch extends React.Component {
  state = {};
  componentDidMount() {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.main.scrollTop = 0;
  }
  render() {
    return (
      <>

      <Helmet>
      <title>Executive Search - ME Talent Solutions</title>
      <meta name="description" content="Global Executive Search with a personal touch. ME Consultancy provides global executive search services for Senior Level / C-Suite individuals, succession planning to both multi-billion organizations, and also private equity clients. The company is highly regarded for its confidential and personalized approach, representing high profile executives and impressive leaders. " />
      <meta name="keywords" content="Global Chief Human Resources Officers Practice, CHRO, Chief People Officer, HR Executive Search, Chief Diversity Officer Recruitment, HR Board Directors & Non-Executive Talent for HR Function, Consumer, Technology, Energy, Retail, Food and Beverage, Production, Packaging" />
      </Helmet>


        <MilesNavbar />

        <MenuHead />



        <main ref="main">

          <section className="section section-lg">
            <Container>
              <Row className="row-grid align-items-center">
                <Col className="order-md-2" md="10">
                  <h3>CHRO Executive Search</h3>
                  <p className="lead text-black mt-4">
                  ME Consultancy specializes in global executive search for CHRO and Senior HR Leaders as a trusted partner for both multi-billion organizations, and also private equity clients. The company is highly regarded for its confidential and personalized approach, representing high profile executives and impressive leaders, and market knowledge.
                  <p />
                  Our mission is to provide a world-class recruitment service where HR Leaders are not just seen as a number in a shortlist, but can be personally identified by their personal journey, successes and key motivators. We work with people to build the world of tomorrow.
                  <p />
                  Working with us has many benefits for you, your business, and your stakeholders. We are known for our highly confidential, efficient, and professional approach, which is based on establishing a unique relationship with each of our clients.
                  <p />
                  Utilizing extensive personal networks, and digital tools we identify, engage, and attract top executive talent and we help our clients retain talent by supporting them beyond the search process.
                  <p />
                  Our focus is high-impact people leaders who can drive breakthrough business performance.

                  </p>

                </Col>

              </Row>
            </Container>
          </section>



          <section className="section pb-0 bg-gradient-white">
            {/* SVG separator */}
            <div className="separator separator-bottom separator-skew zindex-100">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                preserveAspectRatio="none"
                version="1.1"
                viewBox="0 0 2560 100"
                x="0"
                y="0"
              >
                <polygon
                  className="fill-creative"
                  points="0 0 2560 100 0 100"
                />
              </svg>
            </div>
          </section>

          <section className="section section-lg bg-gradient-default">
            <Container className="row-grid align-items-center">
              <Row className="row-grid mt-5">
                <Col lg="10">

                  <h5 className="text-white mt-3">Why Executive Search?</h5>
                  <p className="text-white mt-3">


                  <h3></h3>
                  <p>

                  Executive Search is not right for every search. However, recruiting top performing highly sought leaders is complex, labor-intensive, and requires skilled consultants with a rare set of skills and expertise. If Executive Search did not provide an exceptional talent solution, retained Executive Search firm’s such as ME would not exist.
                  Our clients often use our Executive Search services for the following reasons:
                  <p/>
                  <li><b>Cost of hire</b> is extremely important – it will cost more to onboard a bad hire, than to invest in finding the exceptional senior executive that you require.</li>
                  <li>The hire will be reporting directly to the board/c-suite and needs to be <b>expertly sourced</b>.</li>
                  <li>You are looking for <b>niche talent</b> with a rare mix of skills and expertise.</li>
                  <li>Internal talent search only can identify and source talent that is active on the market. Executive search firms have <b>extensive networks</b> of powerful executives, who are often can only be engaged through a passive approach.</li>
                  <li>The search is <b>confidential</b> and requires necessary NDAs to be completed before presenting your company identity. You may need to find the successor of an under-performing executive within your company.</li>
                  <li>You require an individual for a new-market entry to open up a market/ geography where there is an existing knowledge-gap within your business.</li>
                  <li>Your company needs <b>diverse talent</b> – not just talent from a wide-range of experiences, but also from all backgrounds and diversity groups.</li>
                  <li>You want to target talent from a competitor and there could be serious legal implications to approach them directly.</li>
                  <li>You want to give your company a <b>strategic advantage</b> by attracting top performers.</li>
                  <li>Personal and company networks do not provide the right talent that you need, and you now need to extend your network. Our consultants are among the most well-networked people in the business.</li>

                  </p>
                  </p>

                                      <HRServiceTabs />

                </Col>

              </Row>
            </Container>

          </section>










          <section className="section section-lg">
            <Container>
              <Row className="justify-content-center text-center mb-lg">
                <Col lg="8">
                  <h2 className="display-3">Why ME Consultancy?</h2>
                  <p className="lead text-black mt-4">

Traditionally companies <strong>hire</strong> based on <strong>skills, experience, and knowledge</strong>. However, they <strong>fire</strong> based on <strong>actions, behaviors, and performance</strong>.
<p />We apply a bespoke hiring methodology that <strong>assesses candidate soft skills</strong> and their <strong>specific behavioral characteristics</strong> that can help determine correct alignment in relation to <strong>role requirements, team dynamics</strong>, and the <strong>organizational culture</strong>.
<p />Our <strong>behavioral interview assessments</strong> are developed in line with expected behaviors and soft-skills, required both on a role and company-wide level.

                  </p>
                </Col>

              </Row>
                              <MEForm />
            </Container>
          </section>



        </main>
        <ConsultancyFooter />
      </>
    );
  }
}

export default HRExecSearch;
