import React from "react";
import { Helmet } from 'react-helmet';
// nodejs library that concatenates classes
import classnames from "classnames";

// reactstrap components
import {
  Badge,
  Button,
  Card,
  CardBody,
  CardImg,
  FormGroup,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col
} from "reactstrap";

// core components
import MilesNavbar from "components/Navbars/MilesNavbar.jsx";
import ConsultancyFooter from "components/Footers/ConsultancyFooter.jsx";
import ConsultancyCarousel from "../IndexSections/ConsultancyCarousel.jsx";

// import Hero from "../IndexSections/Hero.jsx";


class MConsultancy extends React.Component {
  state = {};
  componentDidMount() {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.main.scrollTop = 0;
  }
  render() {
    return (
      <>

      <Helmet>
      <title>ME Talent Solutions</title>
      <meta name="description" content="ME Consultancy attracts, cultivates and represents world-leading executive talent, predominantly in Europe, North America, and Asia. Providing Global Executive Search, Executive Assessment, Succession Planning, Business Intelligence, Bespoke HR Software, HR Digital Tool Solutions" />
      <meta name="keywords" content="Executive Search, Executive Assessment, Talent Acquisition, CEO Search, Creative Search, Headhunters, Global Search, CEO, COO, CHRO, CFO, Senior Executive Talent, Board Director & Non-Executive Talent, Creative Talent, Private Equity, CEO, C-Suite, Chief Executive Officer, Board of Director, Non-executive Director, Board Chairperson, Human Resources, HR, People, Marketing & Sales, Strategy & Corporate Planning, Chief Digital Officer, General Counsel, Creative Talent, Creative Directors, Design Director, Chief Digital Officer, Consumer, Technology, Media, Transportation, Healthcare, Industrial" />
      </Helmet>

        <MilesNavbar />




        <ConsultancyCarousel />



        <main ref="main">

          <section className="section section-lg">
            <Container>
              <Row className="row-grid align-items-center">
                <Col className="order-md-2" md="10">
                  <h3>Attract Great Leaders</h3>
                  <p className="lead text-black mt-4">
                    ME Consultancy provides Executive Talent Representation, Succession Planning, Executive Search, and bespoke HR Digital Tool Development.
                  </p>

                  <img
                    alt="..."
                    className="img-fluid"
                    src={require("assets/img/theme/msector.png")}
                  />
                  <p/>

                <strong>Our focus is high-impact leaders, predominantly C-Suite, VP/Director + above, who can drive breakthrough business performance.</strong>

                </Col>

              </Row>
            </Container>
          </section>

          <section className="section section-lg">
            <Container>
              <Row className="row-grid align-items-center">
                <Col className="order-md-2" md="6">
                  <img
                    alt="..."
                    className="img-fluid floating"
                    src={require("assets/img/theme/ac-phone.png")}
                  />
                </Col>
                <Col className="order-md-1" md="6">
                  <div className="pr-md-5">
                    <div className="icon icon-lg icon-shape icon-shape-primary shadow rounded-circle mb-5">
                      <i className="ni ni-spaceship" />
                    </div>
                    <h3>Technology Innovation</h3>
                    <p>

                    ME Consultancy use bespoke tools and innovations to drive Proactive Candidate Engagement and Digital Project Delivery.
                    We strive to keep our finger pressed on the pulse of innovation and invest our profits to allow us
                    to build knowledge in the areas of Artificial Intelligence, Virtual Reality and Realtime Big Data Analytics.

                    </p>
                    <ul className="list-unstyled mt-5">
                      <li className="py-2">
                        <div className="d-flex align-items-center">
                          <div>
                            <Badge
                              className="badge-circle mr-3"
                              color="primary"
                            >
                              <i className="ni ni-atom" />
                            </Badge>
                          </div>
                          <div>
                            <h6 className="mb-0">
                              Continuous Technology Innovation
                            </h6>
                          </div>
                        </div>
                      </li>
                      <li className="py-2">
                        <div className="d-flex align-items-center">
                          <div>
                            <Badge
                              className="badge-circle mr-3"
                              color="primary"
                            >
                              <i className="ni ni-laptop" />
                            </Badge>
                          </div>
                          <div>
                            <h6 className="mb-0">
                              Bespoke Digital Project Delivery Portal
                            </h6>
                          </div>
                        </div>
                      </li>
                      <li className="py-2">
                        <div className="d-flex align-items-center">
                          <div>
                            <Badge
                              className="badge-circle mr-3"
                              color="primary"
                            >
                              <i className="ni ni-spaceship" />
                            </Badge>
                          </div>
                          <div>
                            <h6 className="mb-0">
                              Talent Attraction at the Speed of Business
                            </h6>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </Col>
              </Row>
            </Container>
          </section>

          <section className="section pb-0 bg-gradient-white">
            {/* SVG separator */}
            <div className="separator separator-bottom separator-skew zindex-100">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                preserveAspectRatio="none"
                version="1.1"
                viewBox="0 0 2560 100"
                x="0"
                y="0"
              >
                <polygon
                  className="fill-creative"
                  points="0 0 2560 100 0 100"
                />
              </svg>
            </div>
          </section>

          <section className="section section-lg bg-gradient-default">
            <Container className="pt-lg pb-300">
              <Row className="row-grid mt-5">
                <Col lg="4">
                  <div className="icon icon-lg icon-shape bg-gradient-white shadow rounded-circle text-primary">
                    <i className="ni ni-settings text-primary" />
                  </div>
                  <h5 className="text-white mt-3">Succession Planning</h5>
                  <p className="text-white mt-3">
                  <li>Internal vs External Talent Analysis</li>
                  <li>Human Capital Due Diligence</li>
                  <li>Bespoke Digital Delivery Portal</li>
                  <li>12-month Talent Pooling</li>

                  </p>
                </Col>
                <Col lg="4">
                  <div className="icon icon-lg icon-shape bg-gradient-white shadow rounded-circle text-primary">
                    <i className="ni ni-paper-diploma text-primary" />
                  </div>
                  <h5 className="text-white mt-3">Multi-Placement Projects</h5>
                  <p className="text-white mt-3">
                    We provide options for Succession Planning & Multi-Placement Projects, tailored around the needs of our clients.
                  </p>
                </Col>
                <Col lg="4">
                  <div className="icon icon-lg icon-shape bg-gradient-white shadow rounded-circle text-primary">
                    <i className="ni ni-single-02 text-primary" />
                  </div>
                  <h5 className="text-white mt-3">Talent Assessment</h5>
                  <p className="text-white mt-3">
                  We apply a bespoke hiring methodology that assesses candidate soft skills and their specific behavioral characteristics that
                  can help determine correct alignment in relation to role requirement, team dynamics and the organizational culture.

                  </p>
                </Col>
              </Row>
            </Container>

          </section>

        </main>
        <ConsultancyFooter />
      </>
    );
  }
}

export default MConsultancy;
